<template>
  <CRow>
    <CCol md="6" xs="12" class="add-policy-form">
      <CCard>
        <CCardBody v-if="isLoading">
          <vcl-list></vcl-list>
        </CCardBody>

        <CCardBody v-if="!isLoading">
          <div class="label">
            <label>{{$t('EDIT_AFFILIATE_POLICY_LABEL_TITLE')}}</label>
          </div>
          <div class="wrap-form">
            <AddMembershipPolicy
              v-if="policy.type === 'MEMBERSHIP'"
              :policy="policy"
              :policyId="policyId"
              :options="options"
            />

            <AddAffiliatePolicy
              v-if="policy.type === 'AFFILIATE'"
              :policyId="policyId"
              :policy="policy"
              :options="options"
            />

            <AddMembershipAffiliatePolicy
              v-if="policy.type === 'MEMBERSHIP_AFFILIATE'"
              :policyId="policyId"
              :policy="policy"
              :options="options"
            />
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AddMembershipPolicy from '@/components/AffiliatePolicies/AddMembershipPolicy';
import AddAffiliatePolicy from '@/components/AffiliatePolicies/AddAffiliatePolicy';
import AddMembershipAffiliatePolicy from '@/components/AffiliatePolicies/AddMembershipAffiliatePolicy';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';

export default {
  name: 'EditPolicy',
  components: {
    AddMembershipPolicy,
    AddAffiliatePolicy,
    AddMembershipAffiliatePolicy,
    VclList,
  },
  data() {
    return {
      policy: [],
      policyId: '',
      isLoading: true,
      options: [],
      currencyList: [],
    };
  },
  async mounted() {
    this.policyId = this.$route.params.policyId;

    await Promise.all([this.getPolicyTypes(), this.getStakingCurrencyList(), this.getPolicyDetails()]);
    this.policy.currencySymbol = this.policy.currency_symbol;
    this.policy.proportionShare = Number(this.policy.proportion_share);
    this.policy.maxLevels = this.policy.max_levels;

    if (this.policy.rates) {
      this.policy.rates = this.policy.rates.map(e => {
        return Number(e);
      });
    }

    this.isLoading = false;
  },
  methods: {
    async getPolicyDetails() {
      try {
        const res = await this.$http.get(endpoints.getPolicyDetails(this.policyId));
        this.policy = res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('AFFILIATE_POLICIES_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getPolicyTypes() {
      try {
        const res = await this.$http.get(endpoints.getAffiliatePolicyTypes);
        this.options = res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('AFFILIATE_POLICIES_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getStakingCurrencyList() {
      try {
        const result = await this.$http.get(endpoints.getStakingCurrencyList);
        this.currencyList = result.data.map(item => {
          return {
            ...item,
            value: item.currency_symbol,
            label: item.name,
          };
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('AFFILIATE_POLICIES_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.add-policy-form {
  .label {
    label {
      color: #657187;
      font-size: 18px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 40px;
    }
  }
  .wrap-form {
    .form-group {
      display: flex;
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 5px;
      label {
        width: 35%;
      }
      select,
      input {
        width: 65%;
      }
      &.mb-30 {
        margin-bottom: 30px;
      }
    }
    .group-err-msg {
      width: 65%;
      margin-left: 35%;
      .error-msg {
        font-size: 14px;
        font-weight: 300;
        color: #e40f0f;
      }
    }
    .field-membership-rate {
      .group-err-msg {
        width: 100%;
        margin-left: 0;
      }
    }
    .btn-policy-submit {
      display: block;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      background: #0d0da7;
      padding: 5px 25px;
      outline: none;
      box-shadow: none;
      margin: 0 auto;
    }
  }
}
</style>
